<template>
  <section
    class="realtive md:py-24 py-16"
    id="fasilitas"
  >
    <div class="container relative">
      <div class="grid grid-cols-1 pb-6 text-center">
        <h3 class="font-semibold text-2xl leading-normal mb-4">Fasilitas</h3>

      </div><!--end grid-->

      <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
        <div class="lg:col-span-4 md:col-span-5">
          <div class="sticky top-20">
            <ul
              class="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li role="presentation">
                <button
                  class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500"
                  :class="activeindex === '1' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'"
                  @click="tabChange('1')"
                >
                  <span class="text-lg mt-2 block">Gedung MPP Yang Nyaman</span>

                </button>
              </li>
              <li role="presentation">
                <button
                  class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500 mt-6"
                  :class="activeindex === '2' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'"
                  @click="tabChange('2')"
                >
                  <span class="text-lg mt-2 block">Area Bermain Anak</span>

                </button>
              </li>
              <li role="presentation">
                <button
                  class="px-4 py-2 text-start text-base font-medium rounded-md w-full duration-500 mt-6"
                  :class="activeindex === '3' ? 'text-white bg-teal-500' : 'hover:text-teal-500 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800'"
                  @click="tabChange('3')"
                >
                  <span class="text-lg mt-2 block">Kantin / Tempat Jajanan</span>

                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="lg:col-span-8 md:col-span-7">
          <div
            id="myTabContent"
            class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
          >

            <div
              :class="activeindex !== '1' ? 'hidden' : '' "
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <img
                src="../assets/images/fas1.jpg"
                class="shadow dark:shadow-gray-700 rounded-md"
                alt=""
              >

              <div class="mt-6">
                <h5 class="text-lg font-medium">Gedung MPP Yang Nyaman</h5>
                <p class="text-slate-400 mt-4">Dengan adanya gedung mpp yang nyaman diharapkan masrakat akan lebih merasakan pelayanan yang lebih baik dari kami</p>
              </div>
            </div>

            <div
              :class="activeindex !== '2' ? 'hidden' : '' "
              id="dashboard"
              role="tabpanel"
              aria-labelledby="dashboard-tab"
            >
              <img
                src="../assets/images/fas3.jpg"
                class="shadow dark:shadow-gray-700 rounded-md"
                alt=""
              >

              <div class="mt-6">
                <h5 class="text-lg font-medium">Area Bermain Anak</h5>
                <p class="text-slate-400 mt-4">Dengan adanya area bermain anak dipastikan anda tidak perlu lagi khawatir membawa anak karena MPP ini sangat ramah terhadap anak, sehingga bapak/ibu akan lebih tenang dalam mengurus segala keperluannya</p>

              </div>
            </div>

            <div
              :class="activeindex !== '3' ? 'hidden' : '' "
              id="settings"
              role="tabpanel"
              aria-labelledby="settings-tab"
            >
              <img
                src="../assets/images/fas2.jpg"
                class="shadow dark:shadow-gray-700 rounded-md"
                alt=""
              >

              <div class="mt-6">
                <h5 class="text-lg font-medium">Kantin Atau Tempat Jajanan</h5>
                <p class="text-slate-400 mt-4">Kantin umumnya menyediakan beragam makanan dan minuman, mulai dari makanan berat hingga camilan ringan, dengan pilihan sehat seperti buah-buahan, salad, atau makanan rendah gula dan lemak</p>

              </div>
            </div>
          </div>
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </section>
</template>


<script>
export default {
  data() {
    return {
      activeindex: "1",
    };
  },

  methods: {
    tabChange(index) {
      this.activeindex = index;
    },
  },
};
</script>